import { createAsync, useParams, useSearchParams } from "@solidjs/router";
import { Accessor, ErrorBoundary, Show, Suspense } from "solid-js";

import { HubbleError } from "~/components/error";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { getGiftRouteData } from "~/server/data/gift_route_data";
import { GiftRoot } from "~/components/gifting/gift_root";
import { GiftDetail } from "~/server/types/gift";

export default function Gift() {
  const params = useParams();
  const giftingKey = params.giftingKey;

  const [queryParams, setQueryParams] = useSearchParams();

  const isPreview = queryParams.isPreview === "true";

  const routeData: Accessor<GiftDetail | undefined> = createAsync<GiftDetail>(
    () => {
      return getGiftRouteData(giftingKey);
    },
    {
      deferStream: true,
    }
  );

  return (
    <Suspense>
      <script src="https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js"></script>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <Show when={routeData()}>
        <GiftRoot
          isPreview={isPreview}
          showGuidelines={brandsWithGuideLines.includes(
            routeData()!.brand.brandId
          )}
          brand={routeData()!.brand}
          voucher={routeData()!.voucher}
          amount={routeData()!.voucher.amount}
          description={routeData()!.description}
          senderPhoneNumber={routeData()!.senderPhoneNumber}
          defaultEventProps={{
            giftMode: "APP",
            isPreview: isPreview,
            amount: routeData()!.voucher.amount,
            brandKey: routeData()!.brand.brandKey,
            brandName: routeData()!.brand.brandName,
            brandId: routeData()!.brand.brandId,
            $current_url: "/gift",
          }}
        />
      </Show>
    </Suspense>
  );
}

const brandsWithGuideLines = [
  "01H54MC9866M6DE6EZJ0HV3KVE", // Arrow,
  "01H1XK4QSDG4NWQDE7SVNG27XJ", // Bata,
  "01HQ5YRT89PZEYTPKBYFS2DA3E", // Beer Cafe,
  "01HGDE4FHCV0PS28KJV0G2FKDE", // Beyoung,
  "01H60W8ERJGKFKP869EPMRTZFY", // BIBA,
  "01HKYVS6K7W5AQ837GZAECTB6Z", // Cafe Delhi Heights,
  "01HQ5YRTFBA67MP4ECETSHZD46", // Campus,
  "01HQ5YX4YYG60K3GTE2PQ6F3MS", // GRT Jewellers,
  "01HKYV7HDH25JZR04E7HV0SFRR", // GRT Jewellers (INACTIVE),
  "01H558QVNZBHXYZ68D4KDX2R2W", // Hush Puppies,
  "01HGDE4JKZYRWQXEBXQNZ53XTH", // Organic India,
  "01H6HETVMFRB2HEM3E14Z7FZW6", // Skechers,
  "01HKYVS73AKF7R5PMMJ12JT8HQ", // Skullcandy,
  "01H5Z3AENC8RH99KDJZF9HT2AA", // The Raymond Shop,
  "01HGDE4MJ0F8VQR7BFXKEFT0YD", // TrueFitt & Hill,
  "01H2E9T73K2F1ZNEYGGAF3AJF5", // Vijay Sales,
  "01HHHX1BYMPN1ZCM9X5BJNQBP5", // Wildcraft,
  "01HKYVS7D1EKKJ9W9GGEG88TM8", // William Penn,
];
